import React, {useEffect, useRef} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Banner from "../../components/banner"
import renta1 from "../../../static/img/renta-fija/renta1.jpg"
import renta2 from "../../../static/img/renta-fija/renta2.jpg"
import renta3 from "../../../static/img/renta-fija/renta3.jpg"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Fixed Income Program"/>
            <Banner title="Fixed Income Program"/>
            <section className="about_consulting_area bg-white pt-5 pb-5">
                <div className="container">
                    <div className="row strategy_info">
                        <div className="col-lg-7 order-1 order-lg-0">
                            <div className="ex_content">
                                <h3>Client’s profile</h3>

                                <p>A construction company has significantly moved
                                    forward in the
                                    implementation of its business plan, through the placement of a Eurodollar bond.
                                    Therefore, based on its
                                    knowledge of the financial markets, the company wants to provide the best investor
                                    services to its
                                    bondholders, in parallel to begin developing greater capacities in investor
                                    relations
                                    and corporate
                                    governance that allows it to carry out a subsequent issuance of Eurobonds or an IPO
                                    in
                                    the medium term.</p>
                            </div>
                        </div>
                        <div className="col-lg-5 order-0 order-lg-1 text-center">
                            <img className="video_img wow fadeInRight" src={renta1}
                                 alt={" "}/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about_consulting_area bg-white">
                <div className="container">
                    <div className="row strategy_info">
                        <div className="col-lg-5">
                            <img className="video_img w-100 wow fadeInLeft" src={renta2}
                                 alt={" "}/>
                        </div>
                        <div className="col-lg-7">
                            <div className="ex_content">
                                <h3>Mandate</h3>

                                <p>After performing a beauty contest between IR
                                    consultancy firms in
                                    Mexico, the company decided to contract IRStrat’s Fixed Income Program solution,
                                    with
                                    the mandate to
                                    streamline the process of a subsequent debt placement and lay down the foundations
                                    for a
                                    potential IPO.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pt-5 pb-0">
                <div className="container ex_content">
                    <div className="row m-0">
                        <div className="col-md-12 pl-3 pl-lg-0">
                            <h3 className="s_title d-block">Solution</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section className="executive_area pt-3 pb-4">
                <div className="container">
                    <div className="row mt-3 step service_right_sidebar p-0 mb-5 d-flex align-items-center">
                        <div className="col-lg-7">
                            <div id="accordion7" role="tablist" aria-multiselectable="true"
                                 className="panel-group faq-accordion service_accordion">
                                <div className="card active">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a data-toggle="collapse" data-parent="#accordion7" href="#collapse25"
                                               className="btn-accordion"
                                               aria-expanded="true" role="button" aria-controls="collapse25">
                                                <span className="pluse">+</span><span className="minus">-</span>Strategy
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse25" className="collapse show" aria-expanded="true" role="tabpanel">
                                        <div className="card-block panel-body"><p>Our team, working with the senior
                                            management, begins to
                                            fine-tune the company’s investment thesis, history and competitive
                                            advantage, as well as the
                                            communication style (qualitative and quantitative), an optimal prospecting
                                            and communication
                                            strategy, and an agenda of financial PR. Likewise, disclosure policies are
                                            structured and IR
                                            processes, functions and KPIs are determined. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion7"
                                               href="#collapse26"
                                               className="btn-accordion collapsed" aria-expanded="false"
                                               aria-controls="collapse26">
                                                <span className="pluse">+</span><span className="minus">-</span>Tactic
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse26" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body"><p>IRStrat implements an exhaustive training of our
                                            client’s executive
                                            team in financial communication and develops the necessary capacities for
                                            the execution of
                                            quarterly earnings webcasts. An intensive lobbying and financial PR agenda
                                            are prepared, and a
                                            media exposure strategy focused on the most influential specialized
                                            financial media is
                                            implemented.</p>
                                            <p>IR materials are thoroughly developed to ensure optimal quality,
                                                including earnings release,
                                                annual report, investor presentation, IR website and investor fact
                                                sheet. Likewise, short- and
                                                long-term guidance is developed and widely communicated.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion7"
                                               href="#collapse27"
                                               className="btn-accordion collapsed" aria-expanded="false">
                                                <span className="pluse">+</span><span className="minus">-</span>Results
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse27" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body">Our client is recognized as a leading company in
                                            investor relations and
                                            corporate practices, with a unique positioning and visibility in the
                                            markets. Consequently, new
                                            opportunities for subsequent Eurobonds placement arises, under more
                                            favorable terms and conditions
                                            than its previous bond issuances, including interest rates and maturities.
                                            Similarly, the
                                            perception studies performed by IRStrat show a very positive feedback of the
                                            markets regarding a
                                            potential IPO.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 text-center mt-4 mt-lg-0">
                            <img className="img-fluid w-100 wow fadeInRight" src={renta3} alt={" "}/>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Contenido
